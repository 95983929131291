<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title">{{ title }}</h1>
      <permission-form-box list-url="/permissions" :form-type="formType" @add="onAdd" @edit="onEdit" />
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { mapActions, mapState } from 'vuex';
import PermissionFormBox from '../../components/PermissionFormBox.vue';

export default {
  name: 'PermissionForm',
  components: { PermissionFormBox },
  async mounted() {
    if (this.$route.name === 'permissions-new') {
      this.formType = 'new';
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;
      await this.getOne({
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: ''
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add New Permission' : 'Update Permission';
    },
    ...mapState('permissions', ['loading', 'baseUrl', 'permission'])
  },
  methods: {
    ...mapActions('permissions', ['getOne', 'postOne', 'patchOne']),
    onAdd({ permission }) {
      this.postOne({
        permission,
        router,
        redirectUrl: '/permissions'
      });
    },
    onEdit({ permission }) {
      this.patchOne({
        id: this.id,
        permission,
        router,
        redirectUrl: '/permissions'
      });
    }
  }
};
</script>

<style></style>
